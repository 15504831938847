import { Toast } from 'primereact/toast';
import React, { useRef } from 'react';

import type { NotificationMessage } from '../../app-notifications.types';
import useNotifications from '../../hooks/use-notifications.hook';
import {
  useGetNotificationMessageEventsTypesQuery,
  useGetNotificationMessageTypesQuery,
  useReadMessageMutation
} from '../../services/notifications.api';
import { getNotificationIcon, getNotificationMessage } from '../../utils/notifications.utils';
import NotificationPanel from '../notification-panel';
import NotificationToast from '../notification-toast';

const AppNotifications = () => {
  const container = useRef(null);
  const toast = useRef(null);
  const [readMessage] = useReadMessageMutation();
  const { data: notificationMessageTypesList } = useGetNotificationMessageTypesQuery({});
  const { data: notificationMessageEventsTypesList } = useGetNotificationMessageEventsTypesQuery({});

  const showToast = (detail: string, icon: JSX.Element) => {
    toast.current.show({ content: <NotificationToast message={detail} icon={icon} />, life: 5000 });
  };

  const updateLatestMessage = (messageList: NotificationMessage[]) => {
    const latestTimestamp = Math.max(...messageList.map((message) => message.schedule_date));

    if (lastMessageTimestamp === null) {
      setLastMessageTimestamp(latestTimestamp);
    }

    if (lastMessageTimestamp !== null) {
      const latestMessage = messageList.find((message) => message.schedule_date === latestTimestamp);
      const icon = getNotificationIcon(latestMessage, notificationMessageTypesList);

      const message = getNotificationMessage(latestMessage);

      setLastMessageTimestamp(latestTimestamp);
      showToast(message, icon);
    }
  };

  const onMessageRead = (id: number) => {
    readMessage(id);
  };

  const { lastMessageTimestamp, messages, setLastMessageTimestamp } = useNotifications({
    updateLatestMessage,
    notificationMessageEventsTypesList
  });

  return (
    <div ref={container}>
      <NotificationPanel
        messages={messages}
        onMessageRead={onMessageRead}
        notificationMessageTypesList={notificationMessageTypesList}
      />
      <Toast
        className=""
        ref={toast}
        pt={{
          root: {
            className: 'fixed !top-[100px] !left-[220px] !border-transparent !rounded-md overflow-hidden !opacity-100'
          },
          content: {
            className: 'bg-white rounded-md !p-4'
          }
        }}
      />
    </div>
  );
};

export default AppNotifications;
