import { API_ENDPOINT_KEY } from './API_ENDPOINT_KEY';
import { BUILD_ENV } from './BUILD_ENV';

export const API_ENDPOINT = {
  [BUILD_ENV.DEV]: {
    [API_ENDPOINT_KEY.AUTH]: 'https://dev.api.k8s.mgaru.dev/auth/api/v1/',
    [API_ENDPOINT_KEY.NOTIFICATION]: 'https://dev.api.k8s.mgaru.dev/notification/api/',
    [API_ENDPOINT_KEY.LOGIN]: 'https://mygaru-dev-admin.k8s.mgaru.dev/login',
    [API_ENDPOINT_KEY.DCR]: 'https://dev.api.k8s.mgaru.dev/dcr/api/v1/',
    [API_ENDPOINT_KEY.NOTIFICATION_SOCKET]: 'wss://dev.api.k8s.mgaru.dev/ws',
    [API_ENDPOINT_KEY.DATA_PROCESSING]: 'https://dev.cloud.mgaru.dev'
  },
  [BUILD_ENV.STAGE]: {
    [API_ENDPOINT_KEY.AUTH]: 'https://stage.api.mgaru.dev/auth/api/v1/',
    [API_ENDPOINT_KEY.NOTIFICATION]: 'https://stage.api.mgaru.dev/notification/api/',
    [API_ENDPOINT_KEY.LOGIN]: 'https://mygaru-dev-admin.k8s.mgaru.dev/login',
    [API_ENDPOINT_KEY.DCR]: 'https://stage.api.mgaru.dev/dcr/api/v1/',
    [API_ENDPOINT_KEY.NOTIFICATION_SOCKET]: 'wss://stage.api.mgaru.dev/ws',
    [API_ENDPOINT_KEY.DATA_PROCESSING]: 'https://stage.cloud.mgaru.dev'
  },
  [BUILD_ENV.PRODUCTION]: {
    [API_ENDPOINT_KEY.AUTH]: 'https://api.mygaru.com/auth/api/v1/',
    [API_ENDPOINT_KEY.NOTIFICATION]: 'https://api.mygaru.com/notification/api/',
    [API_ENDPOINT_KEY.LOGIN]: 'https://admin-panel.mygaru.com/login',
    [API_ENDPOINT_KEY.DCR]: 'https://api.mygaru.com/dcr/api/v1/',
    [API_ENDPOINT_KEY.NOTIFICATION_SOCKET]: 'wss://api.mygaru.com/ws',
    [API_ENDPOINT_KEY.DATA_PROCESSING]: 'https://cloud.mgaru.dev'
  }
};
