import { get, keys } from 'lodash';
import lodashIntersection from 'lodash/intersection';
import { useEffect, useRef, useState } from 'react';

import { useAppSelector } from './use-app-selector.hook';

export const usePermissions = (checkedPermission: string[] | void) => {
  const permissions = useRef<string[]>([]);
  const { authData } = useAppSelector((s) => s.auth);
  // eslint-disable-next-line @typescript-eslint/require-await
  const loadPermissions = () => {
    permissions.current = keys(get(authData, 'sessionData.rbac.items', {}));
  };

  const checkPermission = (permissionList: string[] | void) => {
    if (!permissionList) {
      return true;
    }

    if (Array.isArray(permissionList) && permissionList.length) {
      loadPermissions();

      return !!lodashIntersection(permissions.current, permissionList).length;
    }

    return false;
  };

  const initialPermissions = checkPermission(checkedPermission);

  const [isPermitted, setIsPermitted] = useState<boolean | null>(initialPermissions);

  useEffect(() => {
    setIsPermitted(checkPermission(checkedPermission));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      permissions.current = [];
    };
  }, []);

  return { isPermitted, checkPermission, loadPermissions, permissions: permissions.current };
};
