import lodashGet from 'lodash/get';
import lodashUniqBy from 'lodash/uniqBy';
import { useEffect, useState } from 'react';
import useWebSocket from 'react-use-websocket';

import { API_ENDPOINT_KEY } from '../../../constants/API_ENDPOINT_KEY';
import { useAppSelector } from '../../../hooks/use-app-selector.hook';
import { getBaseUrl } from '../../../utils/request.utils';
import {
  type NotificationEvent,
  type NotificationMessage,
  NotificationMessageEventType,
  type NotificationMessageTypeItem,
  NotificationType
} from '../app-notifications.types';

interface UseNotificationsProps {
  updateLatestMessage: (messagesList: NotificationMessage[]) => void;
  notificationMessageEventsTypesList: NotificationMessageTypeItem[];
}

const useNotifications = ({ updateLatestMessage, notificationMessageEventsTypesList }: UseNotificationsProps) => {
  const { authData } = useAppSelector((state) => state.auth);
  const [messages, setMessages] = useState<NotificationMessage[]>([]);
  const [lastMessageTimestamp, setLastMessageTimestamp] = useState<number | null>(null);

  const getSocketUrlWithSid = () => {
    const socketUrl = getBaseUrl(API_ENDPOINT_KEY.NOTIFICATION_SOCKET);

    const sid = lodashGet(authData, ['sessionData', 'sid']);

    return `${socketUrl}?token=${sid}`;
  };

  const { lastJsonMessage } = useWebSocket(getSocketUrlWithSid(), {
    share: true,
    reconnectAttempts: 10,
    reconnectInterval: 3000,
    shouldReconnect: () => true
  });

  const onNewMessageReceived = (messageList: NotificationMessage[]) => {
    setMessages((messages) => {
      const uniqueData = lodashUniqBy([...messages, ...messageList], 'id').sort((a, b) => {
        if (+a.is_important > +b.is_important) {
          return -1;
        }
        if (+a.is_important < +b.is_important) {
          return 1;
        }
        return b.schedule_date - a.schedule_date;
      });

      return uniqueData;
    });
  };

  const removeNotificationItemById = (id: number) => {
    setMessages((prevMessages) => prevMessages.filter((message) => message.id !== id));
  };

  const handleNotificationEvent = (notificationEvent: NotificationEvent) => {
    const eventTypeId = notificationEvent.action;
    const notificationTypeCode = notificationMessageEventsTypesList.find((type) => type.id === eventTypeId).code;

    if (notificationTypeCode === NotificationMessageEventType.READ) {
      removeNotificationItemById(notificationEvent.message_id);
    }
  };

  useEffect(() => {
    if (Array.isArray(lastJsonMessage)) {
      const messageList: NotificationMessage[] = [];

      lastJsonMessage.forEach((item) => {
        if (item.notification_type === NotificationType.MESSAGE) {
          messageList.push(item);
        } else if (item.notification_type === NotificationType.EVENT) {
          handleNotificationEvent(item);
        }
      });

      onNewMessageReceived(messageList);

      if (messageList.length > 0) {
        updateLatestMessage(messageList);
      } else {
        setLastMessageTimestamp(0);
      }
    }
  }, [lastJsonMessage]);

  return {
    messages,
    lastMessageTimestamp,
    setLastMessageTimestamp,
    setMessages
  };
};

export default useNotifications;
