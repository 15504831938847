import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ProtectedRouteWithLayout } from '../../../components/protected-route';
import { PERMISSION_LIST } from '../../../constants/PERMISSION_LIST';
import { usePermissions } from '../../../hooks/use-permissions.hook';

const MainPage = () => {
  const navigate = useNavigate();
  const { isPermitted } = usePermissions([PERMISSION_LIST.DCR_PROFILE_SETTINGS_INDEX]);

  useEffect(() => {
    if (isPermitted) {
      navigate('/profiles/settings');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPermitted]);

  return <ProtectedRouteWithLayout />;
};

export default MainPage;
