import { clearObjEmptyVal } from '@adtelligent-fe/react-common/src/utils/object.utils';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import lodashGet from 'lodash/get';
import type { DataTableState } from 'primereact/datatable';

import { API_ENDPOINT } from '../constants/API_ENDPOINT';
import { API_ENDPOINT_KEY } from '../constants/API_ENDPOINT_KEY';
import { APP_HTTP_HEADERS_KEYS } from '../constants/HTTP_HEADERS_KEYS';
import { getAuthData } from '../modules/auth/utils';
import { getCompiledServiceLink, getCurrentEnvironment } from '../services/apiEnvironment';

export const getPreparedRequestListParams = <
  T extends Pick<DataTableState, 'first' | 'rows' | 'sortField' | 'sortOrder'>
>(
  data: T,
  lookupFields?: string[]
) => {
  const { first: page, rows: limit, sortField, sortOrder, ...filter } = data;
  const result = { page, limit, ...clearObjEmptyVal(filter) };

  if (sortField) {
    result['sort'] = `${+sortOrder === -1 ? '-' : ''}${sortField}`;
  }

  if (filter['search'] && Array.isArray(lookupFields) && lookupFields.length) {
    result['where'] = lookupFields.join();
  }

  return result;
};

export const getPreparedRequestHeaders = (headers: Headers, isDataProcessingUrl?: boolean) => {
  const data = getAuthData();
  const sessionId = lodashGet(data, ['sessionData', 'sid']);
  const isEmulatedSession = lodashGet(data, ['sessionData', 'is_emulated']);
  const currentEnv = getCurrentEnvironment();

  headers.set(APP_HTTP_HEADERS_KEYS.X_ENVIRONMENT, currentEnv);

  if (isEmulatedSession) {
    headers.set(APP_HTTP_HEADERS_KEYS.X_AUTHENTICATION_EMULATE_MODE, `${isEmulatedSession}`);
  }

  if (sessionId) {
    if (isDataProcessingUrl) {
      headers.set(APP_HTTP_HEADERS_KEYS.AUTH_TOKEN, sessionId);
    } else {
      headers.set(APP_HTTP_HEADERS_KEYS.X_AUTHENTICATION_SESSION_ID, sessionId);
    }
  }

  return headers;
};

export const getBaseUrl = (service: string) => {
  const compiledServiceLink = getCompiledServiceLink(service);

  return compiledServiceLink.includes('%prefix%')
    ? compiledServiceLink.replace('%prefix%', getCurrentEnvironment(service))
    : compiledServiceLink;
};

export const getServiceBaseQuery = (service: string) => {
  const isDataProcessingUrl = service === API_ENDPOINT_KEY.DATA_PROCESSING;

  return fetchBaseQuery({
    baseUrl: service.length === 0 ? '' : getBaseUrl(service),
    prepareHeaders: (headers) => getPreparedRequestHeaders(headers, isDataProcessingUrl)
  });
};

export const getLoginPageUrl = () => {
  const currentEnv = getCurrentEnvironment();

  return API_ENDPOINT[currentEnv][API_ENDPOINT_KEY.LOGIN];
};

export const getUrlFilterParams = (params: Record<string, any>) => {
  const filterParams = new URLSearchParams();

  Object.keys(params).forEach((key) => {
    if (Array.isArray(params[key])) {
      params[key].forEach((value) => {
        filterParams.append(key, value);
      });
    } else {
      filterParams.append(key, params[key]);
    }
  });

  return filterParams.toString();
};

export const buildQueryString = (params: Record<string, any>): string => {
  const queryString = new URLSearchParams();

  for (const key in params) {
    const value = params[key];
    if (Array.isArray(value)) {
      value.forEach((val) => queryString.append(key, val));
    } else if (value !== undefined && value !== null) {
      queryString.append(key, value.toString());
    }
  }

  return queryString.toString();
};
