import './service-page-container.scss';

import classNames from 'classnames';
import React from 'react';

import ServicePageHeader from '../service-page-header';

const servicePageWrapperCls = classNames(
  'service-page-wrapper h-screen flex justify-between bg-gray-05 overflow-scroll'
);

const ServicePageContainer = ({ children }) => {
  return (
    <div className={servicePageWrapperCls}>
      <ServicePageHeader />
      <div className="flex flex-col items-center justify-center">{children}</div>
    </div>
  );
};

export default ServicePageContainer;
