export enum NotificationType {
  MESSAGE = 'message',
  EVENT = 'event'
}

export enum NotificationMessageEventType {
  SEND = 'send',
  READ = 'read'
}

export enum NotificationMessageType {
  INFO = 'info',
  ERROR = 'error',
  QUESTION = 'question'
}

export enum QuestionNotificationButtonType {
  SUCCESS = 'success',
  DANGER = 'danger'
}

export enum QuestionNotificationButtonIconType {
  CHECK = 'check',
  CLOSE = 'close'
}

export interface NotificationMessageTypeItem {
  id: number;
  code: NotificationMessageType | NotificationMessageEventType;
  name: string;
}

export interface MessageData {
  added: number;
  errors: number;
  rows: number;
}

export interface QuestionNotificationButtonAction {
  method: string;
  params: string[];
  url: string;
}

export interface QuestionNotificationButton {
  action: QuestionNotificationButtonAction;
  icon: QuestionNotificationButtonIconType;
  name: string;
  type: QuestionNotificationButtonType;
}

export interface NotificationMessage {
  content: {
    data?: MessageData;
    message: string;
    buttons?: QuestionNotificationButton[];
  };
  group: string;
  id: number;
  is_important: boolean;
  notification_type: NotificationType;
  schedule_date: number;
  type: number;
  valid_until: number;
}

export interface NotificationEvent {
  action: number;
  client_id: number;
  id: number;
  message_id: number;
  notification_type: NotificationType;
}
