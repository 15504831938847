import { useAppDispatch } from '@adtelligent-fe/react-common/src/hooks/app-dispatch.hook';
import { AuthReducers } from '@adtelligent-fe/react-common/src/modules/auth';
import classNames from 'classnames';
import lodashGet from 'lodash/get';
import { Menu } from 'primereact/menu';
import type { MenuItem, MenuItemOptions } from 'primereact/menuitem';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { HiArrowRightOnRectangle, HiUsers } from 'react-icons/hi2';
import { HiArrowTrendingUp, HiBriefcase, HiOutlineRectangleGroup, HiOutlineUser } from 'react-icons/hi2';
import { IoDesktopOutline, IoHeadset } from 'react-icons/io5';
import { Link, useNavigate } from 'react-router-dom';

import logo from '../../assets/images/my-garu.svg';
import { useAppSelector } from '../../hooks/use-app-selector.hook';
import AppNotifications from '../../modules/app-notifications';
import { useLogoutMutation } from '../../services/auth.api';
import styles from './sidebar-menu.module.scss';

const SidebarMenu = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { authData } = useAppSelector((state) => state.auth);
  const [logout] = useLogoutMutation();
  const navigate = useNavigate();
  const onLogoutClick = async (): Promise<void> => {
    const sid = lodashGet(authData, ['sessionData', 'sid']);

    if (sid) {
      await logout(sid);
    }

    dispatch(AuthReducers.clearState());
    navigate('/auth');
  };

  const itemTemplate = (item: MenuItem, options: MenuItemOptions) => {
    return (
      <Link
        to={lodashGet(item, 'url', '')}
        className={classNames(options.className, { active: item.url && location.pathname.includes(item.url) })}
        target={item.target}
        onClick={(e) => {
          if (item.command) {
            e.preventDefault();
            item.command({ item, originalEvent: e });
          }
        }}
        data-pr-tooltip={item.label}
      >
        <div className="flex items-center">
          <span>{item.icon}</span>
          {item.label && <span className={classNames(options.labelClassName, 'ml-2')}>{item.label}</span>}
        </div>
      </Link>
    );
  };

  const audienceManagementItems: MenuItem[] = [
    {
      label: t('dashboard'),
      url: '/dashboard',
      template: itemTemplate,
      icon: <HiArrowTrendingUp className="w-5 h-5" />
    },
    {
      label: t('profiles'),
      url: '/profiles',
      template: itemTemplate,
      icon: <HiOutlineUser className="w-5 h-5" />
    },
    {
      label: t('data-insights'),
      url: '/insights',
      template: itemTemplate,
      icon: <HiOutlineRectangleGroup className="w-5 h-5" />
    }
  ];

  const globaleMenuItems: MenuItem[] = [
    {
      template: itemTemplate,
      icon: <HiBriefcase className="w-6 h-6" />
    },
    {
      template: itemTemplate,
      icon: <HiUsers className="w-6 h-6" />
    },
    {
      template: itemTemplate,
      icon: <IoDesktopOutline className="w-6 h-6" />
    },
    {
      template: itemTemplate,
      icon: <HiArrowTrendingUp className="w-6 h-6" />
    },
    {
      template: itemTemplate,
      icon: <IoHeadset className="w-6 h-6" />
    }
  ];

  return (
    <div className="relative flex flex-shrink-0 overflow-hidden rounded-tr-xl rounded-br-xl">
      <div className="pt-[22px] px-[10px] bg-dark-green ">
        <Menu model={globaleMenuItems} className={classNames(styles.menu)} />
      </div>

      <div className="pt-6 px-6 bg-sidebar-menu">
        <div className="flex flex-col mb-4">
          <Link to={'/profiles'}>
            <img src={logo} alt={'App logo'} className="w-48 h-11 mb-2" />
          </Link>
          <div className="flex justify-end">
            <AppNotifications />
            <HiArrowRightOnRectangle
              className="w-6 h-6 cursor-pointer hover:stroke-primary"
              onClick={() => onLogoutClick()}
            />
          </div>
        </div>
        <Menu model={audienceManagementItems} className={classNames('text-lg', styles.menu)} />
      </div>
    </div>
  );
};

export default SidebarMenu;
