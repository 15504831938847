import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../../../../assets/images/my-garu.svg';
import styles from './service-page-header.module.scss';

const ServicePageHeader = () => {
  return (
    <div className="pt-12 px-[142px]">
      <Link to="/">
        <img src={logo} alt="logo" className={styles.logo} />
      </Link>
    </div>
  );
};

export default ServicePageHeader;
