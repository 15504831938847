import type { SignInData, SignInResponse } from '@adtelligent-fe/react-common/src/types/auth.types';
import { createApi } from '@reduxjs/toolkit/query/react';

import { API_ENDPOINT_KEY } from '../constants/API_ENDPOINT_KEY';
import { getServiceBaseQuery } from '../utils/request.utils';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: getServiceBaseQuery(API_ENDPOINT_KEY.AUTH),
  endpoints: (builder) => ({
    logout: builder.mutation<void, string>({
      query(sessionId) {
        return {
          url: `auth/${sessionId}`,
          method: 'DELETE'
        };
      }
    }),
    signInWithJwtToken: builder.query<SignInData, string>({
      query(accessToken) {
        return {
          url: 'auth/jwt',
          headers: { Authorization: `Bearer ${accessToken}` }
        };
      },
      transformResponse: (response: SignInResponse) => response.data
    })
  })
});

export const { useLogoutMutation, useLazySignInWithJwtTokenQuery } = authApi;
