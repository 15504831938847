import type { SignInData } from '@adtelligent-fe/react-common/src/modules/auth/store/types';
import store from '@adtelligent-fe/react-common/src/store';
import { TIMESTAMP_FORMAT, getPreparedTimestamp } from '@adtelligent-fe/react-common/src/utils/date.utils';
import { isJson } from '@adtelligent-fe/react-common/src/utils/string.utils';
import { isAfter } from 'date-fns';
import lodashGet from 'lodash/get';
import lodashIntersection from 'lodash/intersection';

export const getAuthData = (): SignInData | null => {
  const appStore = store.getState();
  const { authData } = appStore.auth;

  if (authData) {
    return authData;
  }

  const data = localStorage.getItem('persist:auth');

  if (data && isJson(data)) {
    const result = JSON.parse(data);

    return JSON.parse(lodashGet(result, ['authData'], '')) || null;
  }

  return null;
};

export const getProfileData = (): any => {
  const data = localStorage.getItem('persist:auth');

  if (data && isJson(data)) {
    const result = JSON.parse(data);

    return JSON.parse(lodashGet(result, ['profileData'], '')) || null;
  }

  return null;
};

export const getAuthId = () => {
  const authData = getAuthData();

  return lodashGet(authData, ['sessionData', 'auth_id']);
};

export const getSessionId = () => {
  const authData = getAuthData();

  return lodashGet(authData, ['sessionData', 'sid']);
};

export const isSessionExpired = () => {
  const authData = getAuthData();
  const expiresAt = lodashGet(authData, ['sessionData', 'expires_at'], 0);

  return isAfter(new Date(), getPreparedTimestamp(expiresAt, TIMESTAMP_FORMAT.JS));
};

export const getUserName = () => {
  const authData = getAuthData();

  return lodashGet(authData, ['sessionData', 'username']);
};

export const getUserRoles = (): string[] => {
  const authData = getAuthData();

  return lodashGet(authData, ['sessionData', 'rbac', 'roles'], []);
};

export const getUserUid = (): number => {
  const authData = getAuthData();

  return lodashGet(authData, ['sessionData', 'uid']);
};

export const getParentUserUid = () => {
  const authData = getAuthData();

  if (!lodashGet(authData, 'sessionData')) {
    return;
  }

  const profileData = getProfileData();

  return lodashGet(profileData, 'parent_id');
};

export const checkRoles = (roles: string[]) => {
  const authData = getAuthData();
  const userRole = lodashGet(authData, ['sessionData', 'rbac', 'roles', 0], '');

  return lodashIntersection(userRole, roles).length;
};
