import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { AuthUtils } from '../../modules/auth';
import GuestLayout from '../guest-layout';
import SuspenseFallback from '../suspense-fallback';

const GuestRoute = () => {
  const initialIsAuthenticated = !AuthUtils.isSessionExpired();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(initialIsAuthenticated);

  useEffect(() => {
    setIsAuthenticated(!AuthUtils.isSessionExpired());
  }, []);

  return isAuthenticated === null ? (
    <SuspenseFallback />
  ) : isAuthenticated === false ? (
    <GuestLayout />
  ) : (
    <Navigate to="/" />
  );
};

export default GuestRoute;
