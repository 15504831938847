import AdBlock from '@adtelligent-fe/react-common/src/components/ad-block';
import GlobalToast from '@adtelligent-fe/react-common/src/components/global-toast';
import UnsupportedBrowser from '@adtelligent-fe/react-common/src/components/unsupported-browser';
import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import SuspenseFallback from '../../components/suspense-fallback';
import MainPage from './pages/main.page';

const Page404 = React.lazy(() => import('@adtelligent-fe/react-common/src/components/page-404'));
const Auth = React.lazy(() => import('../auth'));
const ProfilesPage = React.lazy(() => import('../profiles'));
const InsightsPage = React.lazy(() => import('../insights'));
const DashboardPage = React.lazy(() => import('../dashboard'));
const SegmentsRequests = React.lazy(() => import('../segment-requests'));

const App = () => {
  return (
    <Suspense fallback={<SuspenseFallback />}>
      <Routes>
        <Route index element={<MainPage />} />
        <Route path="/auth/*" element={<Auth />} />
        <Route path="/profiles/*" element={<ProfilesPage />} />
        <Route path="/insights/*" element={<InsightsPage />} />
        <Route path="/dashboard/*" element={<DashboardPage />} />
        <Route path="/requests/*" element={<SegmentsRequests />} />
        <Route
          path="*"
          element={
            <div className="h-full w-full flex justify-center mt-56">
              <Page404 />
            </div>
          }
        />
      </Routes>
      <GlobalToast />
      <AdBlock />
      <UnsupportedBrowser />
    </Suspense>
  );
};

export default App;
