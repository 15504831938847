import { HTTP_STATUS } from '@adtelligent-fe/react-common/src/constants/HTTP_STATUS';
import { createApi } from '@reduxjs/toolkit/query/react';

import { getServiceBaseQuery } from '../../../utils/request.utils';
import { segmentRequestsApi } from '../../segment-requests/services/segment-requests.api';
import { notificationsApi } from './notifications.api';

export const requestStatusesApi = createApi({
  reducerPath: 'requestStatusesApi',
  baseQuery: getServiceBaseQuery(''),
  endpoints: (builder) => ({
    updateRequestStatus: builder.mutation<any, { url: string; method: string; notificationId: number }>({
      query: ({ url, method }) => {
        return {
          url,
          method
        };
      },
      onQueryStarted: async ({ notificationId }, { dispatch, queryFulfilled }) => {
        const { meta } = await queryFulfilled;

        if (HTTP_STATUS.SUCCESS.includes(meta.response.status) && notificationId) {
          dispatch(notificationsApi.endpoints.readMessage.initiate(notificationId));
          dispatch(segmentRequestsApi.util.invalidateTags(['LIST']));
        }
      }
    })
  })
});

export const { useUpdateRequestStatusMutation } = requestStatusesApi;
