import { Badge } from 'primereact/badge';
import { OverlayPanel } from 'primereact/overlaypanel';
import { classNames } from 'primereact/utils';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiBell } from 'react-icons/hi2';
import { IoScanSharp } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

import type { NotificationMessage, NotificationMessageTypeItem } from '../../app-notifications.types';
import { isErrorNotification, isInfoNotification, isQuestionNotification } from '../../utils/notifications.utils';
import NotificatonItem from '../notification-item';

interface NotificationPanelProps {
  messages: NotificationMessage[];
  onMessageRead: (id: number) => void;
  notificationMessageTypesList: NotificationMessageTypeItem[];
}

const NotificationPanel = ({ messages, onMessageRead, notificationMessageTypesList }: NotificationPanelProps) => {
  const popUpRef = useRef<OverlayPanel | null>(null);
  const [view, setView] = useState<'notifications' | 'requests'>('notifications');
  const { t } = useTranslation();
  const navigate = useNavigate();

  const infoNotifications = messages.filter(
    (message) =>
      isInfoNotification(message, notificationMessageTypesList) ||
      isErrorNotification(message, notificationMessageTypesList)
  );

  const questionNotifications = messages.filter((message) =>
    isQuestionNotification(message, notificationMessageTypesList)
  );

  const displayedMessages = view === 'notifications' ? infoNotifications : questionNotifications;

  return (
    <>
      <div
        className="relative cursor-pointer mr-3"
        onClick={(e) => popUpRef.current.toggle(e)}
        onKeyDown={() => {
          return;
        }}
      >
        <HiBell className="w-6 h-6" />
        {messages.length > 0 && (
          <Badge
            value={messages.length}
            severity="info"
            pt={{
              root: {
                className:
                  'flex items-center justify-center min-w-[20px] min-h-[20px] w-[20px] h-[20px] absolute bg-red-400 select-none -top-2 -right-2 text-base'
              }
            }}
          />
        )}
      </div>

      <OverlayPanel ref={popUpRef}>
        <div className="w-72 bg-[#EFEFEF] rounded-md p-2 overflow-hidden">
          <div className="flex items-center justify-between p-1">
            <div className="flex items-center text-dark-blue-700 select-none">
              <div
                className={classNames('p-1 w-fit border-b border-dark-blue-200 text-xs cursor-pointer', {
                  'border-primary': view === 'notifications'
                })}
                onClick={() => setView('notifications')}
                onKeyDown={() => {
                  return;
                }}
              >
                <span>{t('notifications')}</span>
                {infoNotifications.length > 0 && <span className="font-bold"> ({infoNotifications.length})</span>}
              </div>

              <div
                className={classNames('p-1 w-fit border-b border-dark-blue-200 text-xs cursor-pointer', {
                  'border-primary': view === 'requests'
                })}
                onClick={() => setView('requests')}
                onKeyDown={() => {
                  return;
                }}
              >
                <span>{t('requests')}</span>
                {questionNotifications.length > 0 && (
                  <span className="font-bold"> ({questionNotifications.length})</span>
                )}
              </div>
            </div>

            {view === 'requests' && (
              <IoScanSharp
                size={20}
                className="text-dark-blue-900 cursor-pointer"
                onClick={() => navigate('/requests')}
              />
            )}
          </div>

          <div className="bg-white rounded-b-md max-h-96 overflow-y-auto">
            {displayedMessages.length === 0 ? (
              <div className="text-sm text-gray-70 p-4">{t('there-are-no-new-notifications')}</div>
            ) : (
              <div className="overflow-hidden">
                {displayedMessages.map((message, index) => {
                  return (
                    <NotificatonItem
                      key={message.id}
                      message={message}
                      isLastMessage={index === messages.length - 1}
                      onMessageRead={onMessageRead}
                      notificationMessageTypesList={notificationMessageTypesList}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </OverlayPanel>
    </>
  );
};

export default NotificationPanel;
