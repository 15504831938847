import { addToast } from '@adtelligent-fe/react-common/src/components/global-toast';
import { useAppDispatch } from '@adtelligent-fe/react-common/src/hooks/app-dispatch.hook';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IoTrashOutline } from 'react-icons/io5';

import {
  type NotificationMessage,
  type NotificationMessageTypeItem,
  QuestionNotificationButtonType
} from '../../app-notifications.types';
import { useUpdateRequestStatusMutation } from '../../services/request-statuses.api';
import {
  getFormattedDate,
  getNotificationIcon,
  getNotificationMessage,
  isQuestionNotification
} from '../../utils/notifications.utils';

interface NotificatonItemProps {
  message: NotificationMessage;
  isLastMessage: boolean;
  onMessageRead: (id: number) => void;
  notificationMessageTypesList: NotificationMessageTypeItem[];
}
const NotificatonItem = ({
  message,
  isLastMessage,
  onMessageRead,
  notificationMessageTypesList
}: NotificatonItemProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isQuestionNotificationItem = isQuestionNotification(message, notificationMessageTypesList);
  const [updateRequestStatus, { isSuccess: isRequestStatusChanged }] = useUpdateRequestStatusMutation();

  useEffect(() => {
    if (isRequestStatusChanged) {
      dispatch(addToast([{ detail: t('request-status-changed'), severity: 'success' }]));
    }
  }, [isRequestStatusChanged]);

  return (
    <div
      className={classNames('flex flex-col p-2 group relative overflow-hidden', {
        'border-b border-dark-blue-200': !isLastMessage,
        'max-h-16': !isQuestionNotificationItem
      })}
    >
      <div className="flex  items-center overflow-hidden">
        {getNotificationIcon(message, notificationMessageTypesList)}
        <div className="mr-5 text-xs cursor-pointer overflow-hidden text-wrap break-words">
          {getNotificationMessage(message)}
        </div>
        <IoTrashOutline
          size={18}
          className="absolute right-2 shrink-0 cursor-pointer text-red-400 hidden group-hover:block"
          onClick={() => onMessageRead(message.id)}
        />
      </div>

      <div
        className={classNames('flex items-center', {
          'justify-between': isQuestionNotificationItem,
          'justify-end': !isQuestionNotificationItem
        })}
      >
        {isQuestionNotificationItem && (
          <div className="flex items-center mt-2 ml-6 gap-1">
            {message.content?.buttons.map((button) => {
              return (
                <Button
                  key={button.type}
                  label={button.name}
                  className={classNames('!px-2 !py-[6px] text-xs', {
                    'bg-primary': button.type === QuestionNotificationButtonType.SUCCESS,
                    'bg-red-400 hover:bg-red-500': button.type === QuestionNotificationButtonType.DANGER
                  })}
                  onClick={() =>
                    updateRequestStatus({
                      url: button.action.url,
                      method: button.action.method.toUpperCase(),
                      notificationId: message.id
                    })
                  }
                />
              );
            })}
          </div>
        )}
        <div className="self-end text-[10px]">{getFormattedDate(message.schedule_date)}</div>
      </div>
    </div>
  );
};

export default NotificatonItem;
