import { createApi } from '@reduxjs/toolkit/query/react';

import { API_ENDPOINT_KEY } from '../../../constants/API_ENDPOINT_KEY';
import { getServiceBaseQuery } from '../../../utils/request.utils';
import type { NotificationMessageTypeItem } from '../app-notifications.types';

interface GetNotificationsTypesResponse {
  success: boolean;
  data: NotificationMessageTypeItem[];
}

export const notificationsApi = createApi({
  tagTypes: [],
  reducerPath: 'notificationsApi',
  baseQuery: getServiceBaseQuery(API_ENDPOINT_KEY.NOTIFICATION),
  endpoints: (builder) => ({
    getNotificationMessageTypes: builder.query<NotificationMessageTypeItem[], any>({
      query(config = {}) {
        return {
          url: 'v1/dictionaries/message_types',
          ...config
        };
      },
      transformResponse: (response: GetNotificationsTypesResponse) => response.data
    }),
    getNotificationMessageEventsTypes: builder.query<NotificationMessageTypeItem[], any>({
      query(config = {}) {
        return {
          url: 'v1/dictionaries/message_events',
          ...config
        };
      },
      transformResponse: (response: GetNotificationsTypesResponse) => response.data
    }),
    readMessage: builder.mutation<any, number>({
      query(id) {
        return {
          method: 'POST',
          url: `client/v1/messages/${id}/read`
        };
      }
    })
  })
});

export const {
  useReadMessageMutation,
  useGetNotificationMessageTypesQuery,
  useGetNotificationMessageEventsTypesQuery
} = notificationsApi;
