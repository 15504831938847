import React from 'react';
import { Route, Routes } from 'react-router-dom';

import GuestRoute from '../../components/guest-route';

const SignInPage = React.lazy(() => import('./pages/sign-in'));

const Auth = () => {
  return (
    <Routes>
      <Route path="/" element={<GuestRoute />}>
        <Route index element={<SignInPage />} />
      </Route>
    </Routes>
  );
};

export default Auth;
