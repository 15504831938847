import { HTTP_STATUS } from '@adtelligent-fe/react-common/src/constants/HTTP_STATUS';
import type { HttpResponseBody } from '@adtelligent-fe/react-common/src/types/http';
import { getPreparedListQueryData } from '@adtelligent-fe/react-common/src/utils/response.utils';
import { createApi } from '@reduxjs/toolkit/query/react';

import { API_ENDPOINT_KEY } from '../../../constants/API_ENDPOINT_KEY';
import { getServiceBaseQuery } from '../../../utils/request.utils';
import { notificationsApi } from '../../app-notifications/services/notifications.api';
import type { CreateSegmentRequestBody, SegmentRequest, UpdateSegmentRequestParams } from '../segment-requests.types';

export const segmentRequestsApi = createApi({
  reducerPath: 'segmentRequestsApi',
  baseQuery: getServiceBaseQuery(API_ENDPOINT_KEY.DCR),
  tagTypes: ['LIST', 'ITEM'],
  endpoints: (builder) => ({
    createSegmentRequest: builder.mutation<any, CreateSegmentRequestBody>({
      query(payload) {
        return {
          method: 'POST',
          body: payload,
          url: `segment-requests/${payload.id}`
        };
      }
    }),
    updateSegmentRequestStatus: builder.mutation<any, UpdateSegmentRequestParams>({
      query(payload) {
        return {
          method: 'POST',
          url: `segment-requests/${payload.id}/${payload.status}`
        };
      },
      onQueryStarted: async ({ notificationId }, { dispatch, queryFulfilled }) => {
        const { meta } = await queryFulfilled;

        if (HTTP_STATUS.SUCCESS.includes(meta.response.status) && notificationId) {
          dispatch(notificationsApi.endpoints.readMessage.initiate(notificationId));
        }
      },
      invalidatesTags: ['LIST']
    }),
    getSegmentRequests: builder.query({
      transformResponse(response: HttpResponseBody<SegmentRequest[]>, meta) {
        return getPreparedListQueryData(response, meta);
      },
      providesTags: ['LIST'],
      query(params) {
        return {
          url: 'segment-requests',
          params
        };
      }
    })
  })
});

export const { useCreateSegmentRequestMutation, useUpdateSegmentRequestStatusMutation, useGetSegmentRequestsQuery } =
  segmentRequestsApi;
