import './theme-provider.scss';
import '@adtelligent-fe/react-common/src/components/theme-provider/theme-provider.scss';
import classNames from 'classnames';
import { PrimeReactProvider } from 'primereact/api';
import type { ButtonPassThroughMethodOptions } from 'primereact/button';
import { usePassThrough } from 'primereact/passthrough';
import Tailwind from 'primereact/passthrough/tailwind';
import type { TabMenuThroughMethodOptions } from 'primereact/tabmenu';
// biome-ignore lint/style/useImportType: <explanation>
import React from 'react';
import { twMerge } from 'tailwind-merge';

const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const config = usePassThrough(
    Tailwind,
    {
      checkbox: {
        root: {
          className: classNames(
            'cursor-pointer inline-flex relative select-none align-bottom',
            'w-[22px] h-[22px] mr-2'
          )
        },
        input: {
          className: classNames(
            'absolute appearance-none top-0 left-0 w-[22px] h-[22px] p-0 m-0 opacity-0 z-10 outline-none cursor-pointer'
          )
        },
        box: ({ props, context }) => ({
          className: classNames(
            'flex flex-shrink-0 items-center justify-center border-2 w-[22px] h-[22px] text-gray-600 rounded',
            {
              'border-gray-25 bg-white': !context.checked,
              'border-primary bg-primary': context.checked
            },
            {
              'hover:border-blue focus:outline-none focus:outline-offset-0': !props.disabled,
              'cursor-default opacity-60': props.disabled
            }
          )
        }),
        icon: 'w-[16px] h-[16px] text-white text-base'
      },
      inputtext: {
        root: ({ props, context }) => ({
          className: classNames(
            'w-full p-1 rounded-sm text-dark-blue-900 bg-white border border-gray-25 placeholder-dark-blue-300 disabled:text-dark-blue-300 placeholder:text-sm text-sm transition-colors duration-300 ease appearance-none internal-autofill-selected:bg-white',
            {
              'hover:border-border-blue focus:outline-none focus-visible:outline-0 focus:outline-offset-0 focus:border-border-blue focus:shadow-[0_0_0_2.8px_rgba(187,222,251,0.25)]':
                !context.disabled,
              'opacity-60 select-none pointer-events-none cursor-default': context.disabled
            },
            {
              'text-lg px-4 py-4': props.size == 'large',
              'text-xs px-2 py-2': props.size == 'small',
              'p-3 text-base': props.size == null
            }
          )
        }),
        label: { className: 'text-gray-25 text-sm p-0 m-0' }
      },
      inputtextarea: {
        root: ({ context }) => ({
          className: classNames(
            'w-full p-2 border border-gray-25 bg-white placeholder-gray-50 disabled:text-dark-blue-300 placeholder:text-sm placeholder:text-normal text-base hover:bg-white active:bg-white selected:bg-white internal-autofill-selected:bg-white focus-visible:bg-white active:border-blue p-1 rounded-sm',
            'hover:border-border-blue focus:outline-none focus-visible:outline-0 focus:outline-offset-0 focus:border-border-blue focus:shadow-[0_0_0_2.8px_rgba(187,222,251,0.25)] transition-colors duration-300 ease',
            { 'opacity-60 select-none pointer-events-none cursor-default': context.disabled }
          )
        })
      },
      button: {
        root: ({ props, context }: ButtonPassThroughMethodOptions) => {
          return {
            className: classNames(
              'flex items-center focus:outline-0 focus-visible:outline-0 rounded-sm cursor-pointer disabled:cursor-auto py-2 px-4 transition-bg transition-colors duration-300 ease',
              {
                'bg-primary text-white hover:bg-primary-hover disabled:bg-gray-200':
                  !props.severity && !props.text && props.label,
                'bg-transparent text-gray-900': props.icon && !props.label,
                'bg-transparent text-dark-blue-900 font-normal hover:bg-gray-25 disabled:text-dark-blue-900 disabled:hover:bg-transparent':
                  props.text,
                'bg-transparent border border-primary text-primary hover:text-white hover:bg-primary hover:border-primary disabled:border-primary-disabled disabled:text-primary-disabled disabled:hover:bg-transparent disabled:hover:text-primary-disabled':
                  props.severity === 'secondary'
              },
              {
                'py-3 px-6': props.size === 'large',
                'py-2 px-4 text-[14px] leading-[16px] font-normal':
                  props.size === 'small' && props.severity !== 'secondary',
                'py-[7px] px-[15px] font-normal': props.severity === 'secondary',
                'h-8 w-8 !p-0 items-center justify-center': props.icon && !props.label
              }
            )
          };
        },
        label: ({ props }) => ({
          className: classNames('whitespace-nowrap', {
            'font-normal': props.size === undefined,
            'font-medium leading-[19px]': props.size === 'large',
            'text-sm leading-[16px]': props.link,
            hidden: !props.label
          })
        })
      },
      multiselect: {
        root: {
          className:
            ' placeholder-dark-blue-300 inline-flex cursor-pointer select-none bg-white border border-gray-25 transition-colors duration-200 ease-in-out rounded-md text-sm w-full'
        },
        labelcontainer: {
          className: 'overflow-hidden flex flex-auto cursor-pointer'
        },
        label: {
          className:
            'block h-[44px] py-2 px-3 leading-[28px] overflow-hidden whitespace-nowrap curosr-pointer overflow-ellipsis transition-duration-200 py-1.5 px-3'
        },
        tokenlabel: {
          className: 'leading-[20px]'
        }
      },
      dropdown: {
        root: {
          className:
            'cursor-pointer inline-flex relative select-none bg-white dark:bg-white p-3 border border-gray-25 transition-colors rounded-md text-sm w-[215px] h-[46px]'
        },
        header: 'p-3 border-[1px] mt-0 rounded-tr-lg rounded-tl-lg bg-white',
        footer: 'border-[1px] mt-0 rounded-bl-lg rounded-br-lg bg-white',
        filterinput:
          'hover:border-border-blue hover:bg-white focus:outline-none focus-visible:outline-0 focus:outline-offset-0 focus:border-border-blue focus:shadow-[0_0_0_2.8px_rgba(187,222,251,0.25)] transition-colors duration-300 ease w-full p-2 pl-5 border border-gray-25 bg-white placeholder-gray-50 disabled:text-dark-blue-300 placeholder:text-sm text-sm active:bg-white selected:bg-white internal-autofill-selected:bg-white p-1 rounded-sm',
        filtericon: 'absolute -mt-2 absolute top-1/2 right-3 text-gray-500',
        label: { className: 'text-sm focus:outline-0 focus-visible:outline-0' },
        wrapper: { className: 'mt-[2px] overflow-auto' },
        input: {
          className:
            'placeholder-dark-blue-300 cursor-pointer flex flex-auto overflow-hidden overflow-ellipsis whitespace-nowrap relative pr-7'
        },
        itemlabel: 'text-gray-70 dark:text-gray-70',
        list: { className: 'pr-1 py-1 pl-1 list-none m-0 bg-white dark:bg-white rounded-md shadow-md ' },
        item: { className: '!h-fit p-2 m-0 bg-white dark:bg-white text-sm text-gray-70 dark:text-gray-70' },
        trigger: {
          className:
            'flex items-center justify-center shrink-0 bg-transparent text-gray-50 w-2 rounded-tr-lg rounded-br-lg'
        },
        dropdownicon: 'w-3 h-3 shrink-0'
      },
      menubar: {
        root: {
          className: 'py-[10px] px-[24px] flex items-center shadow-[0_2px_2px_rgba(0,0,0,0.15)]'
        },
        button: { className: 'p-0 m-0 bg-white hidden' },
        menu: { className: 'p-0 m-0 flex align-center justify-center text-lg gap-6 font-medium' },
        menuitem: ({ props, context }) => ({
          className: classNames(
            'sm:relative sm:w-auto w-full static hover:text-primary',
            'text-lg',
            { 'rounded-md': props.root },
            {
              'text-gray-70': !context.active,
              'text-primary': context.active
            }
          )
        }),
        end: () => ({
          className: 'ml-none'
        })
      },
      dialog: {
        root: () => ({
          className: classNames('max-w-[420px]')
        }),
        header: { className: 'p-4 flex justify-between items-center bg-white' },
        headerTitle: 'font-lg, leading-[21px] font-medium',
        closeButton: { className: 'm-0' },
        content: { className: 'p-4 pb-[35px] text-sm leading-[16px] bg-white' },
        footer: { className: 'p-4 flex justify-end items-center gap-2 bg-white' }
      },
      card: {
        root: () => ({
          className: classNames('bg-white')
        }),
        content: 'py-0',
        subtitle: { className: 'mb-2 font-bold text-dark' }
      },
      radiobutton: {
        root: {
          className: classNames('relative inline-flex cursor-pointer select-none align-bottom', 'w-6 h-6')
        },
        input: {
          className: classNames(
            'absolute appearance-none top-0 left-0 w-6 h-6 p-0 m-0 opacity-0 z-10 outline-none cursor-pointer'
          )
        },
        box: ({ props }) => ({
          className: classNames(
            'flex items-center justify-center',
            'border-2 w-6 h-6 text-gray-25 rounded-full transition-colors duration-200',
            {
              'border-gray-25 bg-white': !props.checked,
              'border-primary bg-primary': props.checked
            },
            {
              'hover:border-primary focus:outline-none focus:outline-offset-0': !props.disabled,
              'cursor-default opacity-60': props.disabled
            }
          )
        }),
        icon: ({ props }) => ({
          className: classNames('transform rounded-full', 'block w-4 h-4 transition duration-200 bg-white ', {
            'backface-hidden scale-10 invisible': !props.checked,
            'transform scale-100 visible': props.checked
          })
        })
      },
      chip: {
        root: {
          className: classNames('inline-flex items-center', 'bg-info-message text-gray-70 rounded-[6px] px-2')
        },
        label: 'leading-[19px] text-sm mt-2 mb-2',
        icon: 'leading-6 mr-2',
        image: {
          className: classNames('w-9 h-9 ml-[-0.75rem] mr-2', 'rounded-full')
        },
        removeIcon: {
          className: classNames('ml-2 rounded-md transition duration-200 ease-in-out', 'cursor-pointer leading-6')
        }
      },
      tooltip: {
        root: ({ context }) => {
          return {
            className: classNames('absolute', {
              'py-0 px-1':
                context.right || context.left || (!context.right && !context.left && !context.top && !context.bottom),
              'py-1 px-0': context.top || context.bottom
            })
          };
        },
        arrow: ({ context }) => ({
          className: classNames('absolute w-0 h-0 border-transparent border-solid', {
            '-mt-1 border-y-[0.25rem] border-r-[0.25rem] border-l-0 border-r-[#60708B]': context.right,
            '-mt-1 border-y-[0.25rem] border-l-[0.25rem] border-r-0 border-l-[#60708B]': context.left,
            '-ml-1 border-x-[0.25rem] border-t-[0.25rem] border-b-0 border-t-[#60708B]': context.top,
            '-ml-1 border-x-[0.25rem] border-b-[0.25rem] border-t-0 border-b-[#60708B]': context.bottom
          })
        }),
        text: {
          className: 'p-2 bg-[#60708B] text-sm leading-[16px] text-white rounded-md whitespace-pre-line break-words'
        }
      },
      datatable: {
        loadingicon: 'w-8 h-8 mt-[100px]',
        column: {
          headercell: { className: 'border border-[#dfdfdf] bg-gray-10 text-sm font-medium text-left p-4' },
          headercontent: { className: 'flex justify-between' },
          bodycell: { className: 'bg-white border border-[#dfdfdf] text-sm leading-[16px] text-gray-70 text-left p-4' },
          sorticon: { className: 'text-primary' }
        },
        bodyrow: 'bg-white dark:bg-white'
      },
      paginator: {
        root: {
          className: classNames(
            'flex items-center justify-center flex-wrap gap-4',
            'bg-white dark:bg-white text-[#3C3C3C]'
          )
        },
        current: { className: 'text-sm mr-6 text-gray-60' },
        firstPageButton: { className: 'disabled:text-[#F0F0F0] text-[#8C8C8C] mr-4' },
        prevPageButton: { className: 'disabled:text-[#F0F0F0] text-[#8C8C8C] mr-4' },
        nextPageButton: { className: 'disabled:text-[#F0F0F0] text-[#8C8C8C] mr-4' },
        lastPageButton: { className: 'disabled:text-[#F0F0F0] text-[#8C8C8C] mr-4' },
        pagebutton: { className: 'w-fit h-fit' },
        pages: { className: 'bg-white px-2 py-1 text-sm mr-4 rounded-[3px] leading-[16px]' },
        RPPDropdown: {
          root: { className: 'w-[70px]' },
          input: { className: 'p-0' },
          panel: { className: 'bg-white dark-bg-white' }
        }
      },
      tabmenu: {
        root: 'inline-block',
        menu: {
          className: classNames('flex m-0 p-0 list-none flex-nowrap', 'outline-none no-underline text-base list-none')
        },
        menuitem: ({ context, state }: TabMenuThroughMethodOptions) => ({
          className: classNames(
            'mr-0',
            'cursor-pointer select-none flex items-center relative no-underline overflow-hidden',
            'border-b-2 p-3 font-bold rounded-t-lg ',
            {
              'border-dark-blue-200 text-dark-blue-800 hover:border-gray-400 hover:text-gray-600':
                state.activeIndex !== context.index,
              'border-primary text-dark-blue-800': state.activeIndex === context.index
            }
          )
        }),
        inkbar: 'hidden'
      },
      fileupload: {
        input: 'hidden',
        buttonbar: {
          className: classNames(
            'flex flex-wrap',
            'bg-gray-50 p-5 border border-solid border-gray-300 text-gray-700 rounded-tr-lg rounded-tl-lg gap-2 border-b-0'
          )
        },
        basicButton: {
          className: classNames(
            'bg-primary text-white text-sm rounded-md py-2 px-4',
            'overflow-hidden relative cursor-pointer'
          )
        },
        chooseButton: {
          className: classNames(
            'bg-primary text-white text-sm rounded-md py-2 px-4 hover:bg-primary-hover transition-colors duration-300 ease',
            'overflow-hidden relative cursor-pointer'
          )
        },
        content: {
          className: classNames('relative', 'bg-white p-8 border border-gray-300 text-gray-700 rounded-b-lg')
        }
      },
      overlaypanel: {
        root: {
          className: classNames(
            'bg-white dark-bg-white text-gray-700 border-0 rounded-md shadow-lg',
            'z-40 transform origin-center',
            'absolute left-0 top-0 mt-3',
            'before:hidden'
          )
        },
        closeButton: 'flex items-center justify-center overflow-hidden absolute top-0 right-0 w-6 h-6',
        content: 'items-center flex rounded-md'
      },
      menu: {
        root: ({ props }) => ({
          className: classNames(
            'cursor-pointer inline-flex relative select-none',
            'bg-white dark:bg-white border',
            'w-full md:w-56',
            'focus:outline-none focus:outline-offset-0',
            { 'opacity-60 select-none pointer-events-none cursor-default': props.disabled }
          )
        }),
        menu: 'w-full',
        menuitem: 'hover:bg-transparent dark:hover:bg-transparent',
        content: 'hover:bg-transparent dark:hover:bg-trasparent',
        action: 'select-none cursor-pointer flex items-center no-underline overflow-hidden relative'
      },
      password: {
        root: ({ props }) => ({
          className: classNames('inline-flex relative w-full', {
            'opacity-60 select-none pointer-events-none cursor-default': props.disabled
          })
        }),
        iconfield: {
          root: ({}) => ({
            className: 'w-full'
          })
        },
        panel: 'hidden',
        meter: 'hidden',
        meterlabel: 'hidden',
        inputtext: { className: 'w-full' },
        showicon: {
          className: classNames('absolute top-1/2 -mt-2', 'right-3 text-gray-60 cursor-pointer')
        },
        hideicon: {
          className: classNames('absolute top-1/2 -mt-2', 'right-3 text-gray-60 cursor-pointer')
        }
      }
    },
    {
      mergeSections: true,
      mergeProps: false,
      classNameMergeFunction: twMerge
    }
  );

  return (
    <PrimeReactProvider
      value={{
        unstyled: true,
        pt: config
      }}
    >
      {children}
    </PrimeReactProvider>
  );
};

export default ThemeProvider;
