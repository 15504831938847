import { ProgressBar } from 'primereact/progressbar';
import React from 'react';

export const SuspenseFallback = () => {
  return (
    <div className="h-screen w-full flex items-center justify-center flex-col">
      <ProgressBar
        mode="indeterminate"
        className="w-25 mt-4 h-[10px] w-full max-w-[600px]"
        pt={{
          value: { className: 'bg-primary' }
        }}
      />
    </div>
  );
};

export default SuspenseFallback;
