import React from 'react';
import { Outlet } from 'react-router-dom';

import ServicePageContainer from '../service-page-container';

const GuestLayout = () => {
  return (
    <ServicePageContainer>
      <Outlet />
    </ServicePageContainer>
  );
};

export default GuestLayout;
