import React from 'react';

interface NotificationToastProps {
  message: string;
  icon: JSX.Element;
}

const NotificationToast = ({ message, icon }: NotificationToastProps) => {
  return (
    <div className="flex w-full flex-col">
      <div className="flex items-center">
        {icon}
        <div className="text-base font-normal flex flex-col flex-1 shrink ml-4">{message}</div>
      </div>
    </div>
  );
};

export default NotificationToast;
