import { useAppDispatch } from '@adtelligent-fe/react-common/src/hooks/app-dispatch.hook';
import { AuthReducers } from '@adtelligent-fe/react-common/src/modules/auth';
import { persistor } from '@adtelligent-fe/react-common/src/store';
import { useEffect, useState } from 'react';

import { AuthUtils } from '../modules/auth';

export const useRouteProtection = () => {
  const dispatch = useAppDispatch();
  const initialIsAuthenticated = !AuthUtils.isSessionExpired();

  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(initialIsAuthenticated);

  useEffect(() => {
    if (AuthUtils.isSessionExpired()) {
      dispatch(AuthReducers.clearState());
      persistor.flush().then(() => {
        setIsAuthenticated(false);
      });
    } else {
      setIsAuthenticated(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isAuthenticated };
};
