import type { MenuItem } from 'primereact/menuitem';
import type { FC } from 'react';
import React from 'react';
import { Outlet } from 'react-router-dom';

import SidebarMenu from '../sidebar-menu';

interface ProtectedLayoutProps {
  contentLayout?: FC;
  sidebarItems?: MenuItem[];
}

const ProtectedLayout: FC<ProtectedLayoutProps> = ({ contentLayout: ContentLayout, sidebarItems }) => {
  return (
    <div className="flex flex-col h-full bg-[--body-bg]">
      <main className="flex grow overflow-hidden">
        <SidebarMenu />
        <section className="flex flex-col overflow-auto flex-1 px-9 py-7 bg-dark-blue-50">
          {ContentLayout ? <ContentLayout /> : <Outlet />}
        </section>
      </main>
    </div>
  );
};

export default ProtectedLayout;
