import { injectMiddlewares, injectReducers } from '@adtelligent-fe/react-common/src/store';

import { authApi } from '../../services/auth.api';
import { clientApi } from '../../services/client.api';
import Auth from './auth';
import * as AuthUtils from './utils';

injectReducers([
  { name: authApi.reducerPath, reducer: authApi.reducer },
  { name: clientApi.reducerPath, reducer: clientApi.reducer }
]);
injectMiddlewares([authApi.middleware, clientApi.middleware]);

export default Auth;
export { AuthUtils };
