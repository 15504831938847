import translationEN from '@adtelligent-fe/react-common/src/i18n/dictionaries/en';

export default {
  ...translationEN,
  profiles: 'Profiles',
  insights: 'Insights',
  settings: 'Settings',
  'add-profiles': 'Add profiles',
  column: 'Column',
  property: 'Property',
  'enter-property': 'Enter property name',
  'columns-unique': 'Columns must be unique',
  'columns-names-unique': 'Columns names must be unique',
  'pause-profile': 'Pause Profile',
  'delete-profile': 'Delete Profile',
  'download-segments': 'Download Segments',
  'data-source': 'Data Source',
  'import-from': 'Import from',
  'id-mapping-score': 'ID Mapping Score',
  'improve-id-mapping': 'Improve ID Mapping',
  'pause-profiles': 'Pause Profiles',
  'delete-profiles': 'Delete Profiles',
  'upload-file': 'Please upload a file',
  'create-category': 'Create Category',
  'create-segment': 'Create Segment',
  categories: 'Categories',
  segments: 'Segments',
  ownership: 'Ownership',
  access: 'Access',
  'my-ownership': 'I am owner',
  'someone-ownership': 'Someone is owner',
  private: 'Private',
  shared: 'Shared',
  public: 'Public',
  favourite: 'Favourite',
  'show-category-details': 'Show category details',
  'category-details': 'Category details',
  'show-segment-details': 'Show segment details',
  'category-name': 'Category name',
  'segment-name': 'Segment name',
  category: 'Category',
  'edit-category': 'Edit Category',
  'edit-segment': 'Edit segment',
  'pricing-model': 'Pricing model',
  'analysis-price': 'Analysis price',
  'price-cpm': 'Price(CPM)',
  'enter-category-name': 'Enter category name',
  'enter-category-description': 'Enter category description',
  'per-category': 'Per category',
  'per-segment': 'Per segment',
  'touch-price': 'Touch price',
  'overlap-analysis-price': 'Overlap analysis price',
  'hide-audience': 'Hide Audience',
  cpm: 'CPM',
  'save-changes': 'Save Changes',
  'last-touch': 'Last touch',
  'last-month-revenue': 'Revenue Last Month',
  'total-revenue': 'Total Revenue',
  'delete-category': 'Delete Category',
  'segments-in-category': 'Segments in Category',
  audience: 'Audience',
  'last-update': 'Last update',
  'segment-details': 'Segment details',
  'select-category': 'Select category',
  plain: 'Plain',
  composite: 'Composite',
  'super-segment': 'Super Segment',
  days: 'Days',
  lifetime: 'Lifetime',
  segment_type: 'Segment type',
  'must-contain-partner-uids': 'The data in table must contain a list of Partner UIDs',
  'download-csv-template': 'Download template of .csv file',
  'id-value': 'Identifying Value',
  'properties-mapping': 'Properties Mapping',
  automatic: 'Automatic',
  manual: 'Manual',
  access_type: 'Access type',
  'visibility-types': 'Visibility types',
  'visibility-type': 'Visibility type',
  'select-upload-file': 'Drag and drop file with the list or click on button to upload it',
  'select-upload-category-image': 'Drag and drop category cover here or click on button to upload it',
  'select-upload-segment-image': 'Drag and drop segment cover here or click on button to upload it',
  'run-overlap-analysis': 'Run overlap analysis',
  'run-overlap-analysis-for': 'Run overlap analysis for {{value}}',
  'data-partner-segments': 'Other Segments of Data Partner',
  'composite-segment-builder': 'Composite Segment Builder',
  'revenue-priority-settings': 'Revenue priority settings',
  'priority-by-price': 'Priority by price',
  'custom-priority': 'Custom Priority',
  equally: 'Equally',
  'data-partner': 'Data partner',
  'send-request': 'Send Request',
  'custom-price': 'Custom Price',
  'custom-analysis-price': 'Custom Analysis Price',
  'add-to-favourite': 'Add to Favourite',
  'request-access': 'Request Access',
  'pause-segment': 'Pause Segment',
  'delete-segment': 'Delete Segment',
  'composite-segment-details': 'Composite Segment details',
  'used-segments': 'Used Segments',
  targeting: 'Targeting',
  analysis: 'Analysis',
  'revenue-last-month': 'Revenue Last Month',
  'usage-type': 'Usage type',
  'notification-type': 'Notification type',
  'request-status': 'Request status',
  notifications: 'Notifications',
  requests: 'Requests',
  received: 'Recieved',
  sent: 'Sent',
  activity: 'Activity',
  union: 'Union',
  intersection: 'Intersection',
  subtraction: 'Subtraction',
  'segment-statistics': 'Segment statistics',
  'requests-notifications': 'Requests and notifications',
  'api-endpoint': 'API Endpoint',
  'server-address': 'Server Address',
  'user-name': 'User name',
  password: 'Password',
  'file-path': 'File path',
  'select-partner': 'Please, select at least one partner',
  'please-select-segments': 'Please, select segments',
  'select-values': 'Please, select values',
  'enter-segment-name': 'Enter segment name',
  'enter-segment-description': 'Enter segment description',
  'select-column': 'Select column',
  'expiration-date': 'Expiration Date',
  'success-category-update': 'Category updated successfully',
  'success-category-create': 'Category created successfully',
  'success-category-delete': 'Category deleted successfully',
  'success-segment-update': 'Segment updated successfully',
  'success-segment-create': 'Segment created successfully',
  'success-segment-delete': 'Segment deleted successfully',
  'success-profile-settings-update': 'Profile settings updated successfully',
  'warn-cant-delete-category': "Can't delete category with segments in it",
  'select-property': 'Select property',
  'properties-unique': 'Properties must be unique',
  'data-insights': 'Data Insights',
  'sucess-add-profiles': 'Submission successful. Please wait for a notification about the profiles upload report.',
  'select-two-segments-minimum': 'Select at least two segments',
  'image-dimensions-error': 'Image dimensions should not exceed 1000px in width and 500px in height.',
  consent: 'Consent',
  'data-mart': 'Data Mart',
  'select-partners': 'Select Partners',
  select: 'Select',
  'segment-cover': 'Segment cover',
  data: 'Data',
  'pricing-and-access': 'Pricing and Access',
  advanced: 'Advanced',
  'there-are-no-new-notifications': 'There are no new notifications',
  login: 'Login',
  properties: 'Properties',
  'column-mapping': 'Column mapping',
  'add-more': 'Add more',
  'add-property': 'Add property',
  'identifying-values': 'Identifying Values',
  'column-type': 'Column type',
  'column-name': 'Column name',
  'phone-number': 'Phone number',
  'data-stream-api-properties': 'Data Stream API Properties',
  counter: 'Counter',
  numeric: 'Numeric',
  'select-at-least-one-data-partner': 'Select at least one data partner',
  profile_import_result_success: 'user profiles uploaded successfully',
  profile_import_result_fail: 'Profiles uploading failed',
  segment_import_result_success: 'Segment data uploaded successfully',
  segment_import_result_fail: 'Segment data uploading failed',
  visibility: 'Visibility',
  basic: 'Basic',
  sql: 'SQL',
  operator: 'Operator',
  values: 'Values',
  and: 'And',
  or: 'Or',
  csv: 'Csv',
  'unable-to-sign-in': 'Unable to sign in',
  'return-to-login-page': 'Return to login page',
  'success-add-profiles': 'Profiles successfully added',
  'success-profile-deletion': 'Profile deleted successfully',
  'success-profile-pause': 'Profile paused successfully',
  'invalid-file-type': 'Invalid file type',
  operation: 'Operation',
  result: 'result',
  'segment-file': 'Segment file',
  partners: 'Partners',
  'source-segments': 'Source segments',
  'target-segments': 'Target segments',
  action: 'Action',
  'operation-n-result': 'Operation {{value}} result',
  'select-unique-actions': 'Select unique actions',
  'audience-size': 'Audience size',
  'account-name': 'Account name',
  'account-key': 'Account key',
  'container-name': 'Container name',
  'file-name': 'File name',
  'access-key': 'Access key',
  'secret-access-key': 'Secret access key',
  bucket: 'Bucket',
  region: 'Region',
  'validate-query': 'Validate query',
  'sql-query-invalid': 'The Query is invalid, please check the syntax and Properties spelling',
  'query-ran-successfully': 'Query ran successfully',
  'request-sent': 'Request sent',
  'request-status-changed': 'Request status changed',
  'request-date': 'Request date',
  approve: 'Approve',
  decline: 'Decline',
  pending: 'Pending',
  approved: 'Approved',
  declined: 'Declined',
  'max-segments-in-composite-error': 'The maximum number of segments in a composite segment is {{value}}',
  'no-category': 'No category',
  external_uid: 'External UID',
  paused: 'Paused',
  'resume-profile': 'Resume profile',
  'success-profile-resume': 'Profile resumed successfully',
  exclusion: 'Exclusion',
  'select-a-segment': 'Select a segment',
  'select-segments': 'Select segments'
};
