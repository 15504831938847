import { getCookie } from '@adtelligent-fe/react-common/src/utils/cookie.utils';

import { API_ENDPOINT } from '../constants/API_ENDPOINT';
import { API_ENDPOINT_KEY } from '../constants/API_ENDPOINT_KEY';
import { BUILD_ENV } from '../constants/BUILD_ENV';

const DEFAULT_ENV_COOKIE_NAME = 'env';

const getEnvFromCookie = (name: string) => {
  name = name || DEFAULT_ENV_COOKIE_NAME;

  const cookieVal = getCookie(name);

  return cookieVal ? decodeURIComponent(cookieVal) : undefined;
};

export const getCurrentEnvironment = (envCookieName = DEFAULT_ENV_COOKIE_NAME) => {
  let envPrefix: string;
  let env = getEnvFromCookie(envCookieName);

  if ((envCookieName !== DEFAULT_ENV_COOKIE_NAME && !env) || env === undefined) {
    env = getEnvFromCookie(DEFAULT_ENV_COOKIE_NAME) || import.meta.env.MODE;
  }

  switch (env) {
    case 'prod':
    case 'production':
      envPrefix = BUILD_ENV.PRODUCTION;
      break;
    case 'stage':
    case 'staging':
      envPrefix = BUILD_ENV.STAGE;
      break;
    case 'dev':
    case 'development':
      envPrefix = BUILD_ENV.DEV;
      break;
    default:
      envPrefix = env || BUILD_ENV.DEV;
  }

  return envPrefix;
};

export const getCompiledServiceLink = (service: string) => {
  const currentEnv = getCurrentEnvironment(service);
  const endpointLink = API_ENDPOINT[currentEnv]
    ? API_ENDPOINT[currentEnv][service]
    : API_ENDPOINT[BUILD_ENV.DEV][service];

  return prepareLink(endpointLink);
};

export const getCurrentHost = () => {
  const { location } = window;

  return `https://${location.hostname}`;
};

export const getServiceNameByLink = (link: string): string | void => {
  if (link.match(/https?:\/\/auth./i)) {
    return API_ENDPOINT_KEY.AUTH;
  }
  if (link.match(/https?:\/\/dcr./i)) {
    return API_ENDPOINT_KEY.DCR;
  }
  if (link.match(/https?:\/\/notification./i)) {
    return API_ENDPOINT_KEY.NOTIFICATION;
  }

  return undefined;
};

export const prepareLink = (endpoint: string, replacerParamList: Record<string, any> = {}) => {
  const service = getServiceNameByLink(endpoint);
  const params = {
    prefix: service ? getCurrentEnvironment(service) : '',
    ...replacerParamList
  };

  return endpoint.replace(/%(.+?)%/g, (matchData, key: string) => {
    return (params[key] || matchData) as string;
  });
};
