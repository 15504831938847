import type { MenuItem } from 'primereact/menuitem';
import type { FC } from 'react';
import React from 'react';
import { Navigate } from 'react-router-dom';

import { usePermissions } from '../../hooks/use-permissions.hook';
import { useRouteProtection } from '../../hooks/use-route-protection.hook';
import ProtectedLayout from '../protected-layout';
import SuspenseFallback from '../suspense-fallback';

interface ProtectedRoute {
  permissions?: string[];
  contentLayout?: FC;
  sidebarItems?: MenuItem[];
}

export const ProtectedRouteWithLayout: FC<ProtectedRoute> = ({ permissions, contentLayout, sidebarItems }) => {
  const { isPermitted } = usePermissions(permissions);
  const { isAuthenticated } = useRouteProtection();

  if (isAuthenticated === null) {
    return <SuspenseFallback />;
  }
  if (isAuthenticated === true) {
    if (isPermitted === false) {
      return <Navigate to="/" />;
    }

    return <ProtectedLayout contentLayout={contentLayout} sidebarItems={sidebarItems} />;
  }

  return <Navigate to="/auth" />;
};
