import { format, fromUnixTime } from 'date-fns';
import { t } from 'i18next';
import lodashGet from 'lodash/get';
import React from 'react';
import { HiCheckCircle, HiExclamationCircle } from 'react-icons/hi2';

import {
  type NotificationMessage,
  NotificationMessageType,
  type NotificationMessageTypeItem
} from '../app-notifications.types';

export const getFormattedDate = (timestamp: number) => {
  const date = fromUnixTime(timestamp);
  const formattedDate = format(date, 'dd/MM/yyyy, HH:mm');

  return formattedDate;
};

export const isInfoNotification = (
  message: NotificationMessage,
  notificationMessageTypesList: NotificationMessageTypeItem[]
) => {
  const messageTypeId = message.type;
  const infoNotificationMessageData = notificationMessageTypesList.find(
    (notifMessage) => notifMessage.code === NotificationMessageType.INFO
  );

  return messageTypeId === infoNotificationMessageData.id;
};

export const isErrorNotification = (
  message: NotificationMessage,
  notificationMessageTypesList: NotificationMessageTypeItem[]
) => {
  const messageTypeId = message.type;
  const errorNotificationMessageData = notificationMessageTypesList.find(
    (notifMessage) => notifMessage.code === NotificationMessageType.ERROR
  );

  return messageTypeId === errorNotificationMessageData.id;
};

export const isQuestionNotification = (
  message: NotificationMessage,
  notificationMessageTypesList: NotificationMessageTypeItem[]
) => {
  const messageTypeId = message.type;
  const errorNotificationMessageData = notificationMessageTypesList.find(
    (notifMessage) => notifMessage.code === NotificationMessageType.QUESTION
  );

  return messageTypeId === errorNotificationMessageData.id;
};

export const getNotificationMessage = (message: NotificationMessage) => {
  if (message.content.message === 'profile_import_result_success') {
    return `${lodashGet(message, 'content.data.added', '')} ${t(message.content.message)}`;
  }

  return t(message.content.message);
};

export const getNotificationIcon = (
  message: NotificationMessage,
  notificationMessageTypesList: NotificationMessageTypeItem[]
) => {
  if (isInfoNotification(message, notificationMessageTypesList)) {
    return <HiCheckCircle size={24} className="text-primary flex-shrink-0" />;
  }

  if (isErrorNotification(message, notificationMessageTypesList)) {
    return <HiExclamationCircle size={24} className="text-red-400 flex-shrink-0" />;
  }

  if (isQuestionNotification(message, notificationMessageTypesList)) {
    return <HiExclamationCircle size={24} className="text-dark-yellow flex-shrink-0" />;
  }

  return <HiCheckCircle size={24} className="text-primary" />;
};
