import { injectMiddlewares, injectReducers } from '@adtelligent-fe/react-common/src/store';

import { segmentRequestsApi } from '../segment-requests/services/segment-requests.api';
import AppNotifications from './components/app-notifications';
import { notificationsApi } from './services/notifications.api';
import { requestStatusesApi } from './services/request-statuses.api';

injectReducers([
  { name: notificationsApi.reducerPath, reducer: notificationsApi.reducer },
  { name: requestStatusesApi.reducerPath, reducer: requestStatusesApi.reducer },
  { name: segmentRequestsApi.reducerPath, reducer: segmentRequestsApi.reducer }
]);
injectMiddlewares([notificationsApi.middleware, requestStatusesApi.middleware, segmentRequestsApi.middleware]);
export default AppNotifications;
