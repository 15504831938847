import type { ClientData } from '@adtelligent-fe/react-common/src/types/client.types';
import type { HttpResponseBody } from '@adtelligent-fe/react-common/src/types/http';
import { getPreparedListQueryData } from '@adtelligent-fe/react-common/src/utils/response.utils';
import { createApi } from '@reduxjs/toolkit/query/react';
import { API_ENDPOINT_KEY } from '../constants/API_ENDPOINT_KEY';
import { getServiceBaseQuery } from '../utils/request.utils';

export const clientApi = createApi({
  tagTypes: ['AVAILABLE_TO_LIST', 'CLIENTS_DICTIONARY'],
  reducerPath: 'clientApi',
  baseQuery: getServiceBaseQuery(API_ENDPOINT_KEY.DCR),
  endpoints: (builder) => ({
    getClient: builder.query<ClientData, { client_id: number }>({
      transformResponse(response: HttpResponseBody<ClientData>) {
        return response.data;
      },
      query: ({ client_id }) => ({
        url: `clients/${client_id}`
      })
    }),
    getClients: builder.query({
      transformResponse(response: HttpResponseBody<ClientData[]>, meta) {
        return getPreparedListQueryData(response, meta);
      },
      query(params) {
        return {
          url: 'clients',
          params
        };
      }
    }),
    getCurrentClient: builder.query({
      transformResponse(response: HttpResponseBody<ClientData>, meta) {
        return getPreparedListQueryData(response, meta);
      },
      query(clientId) {
        return {
          url: `clients/current/${clientId}`
        };
      }
    }),
    update: builder.mutation<void, Partial<ClientData>>({
      invalidatesTags: ['AVAILABLE_TO_LIST'],
      query({ id, ...body }) {
        return {
          body,
          url: `clients/${id}`,
          method: 'PUT'
        };
      }
    })
  })
});

export const {
  useGetCurrentClientQuery,
  useLazyGetCurrentClientQuery,
  useLazyGetClientQuery,
  useUpdateMutation,
  useLazyGetClientsQuery
} = clientApi;
